<template>
    <a-card>
        <a-page-header
            title='绑定项目'
        />
        <a-form-model
            ref="dataForm"
            :model="dataForm"
            :rules='rules'
            v-bind='layout'
        >
            <a-form-model-item label="项目名称" prop="projectId">
                <a-select
                    v-model="dataForm.projectId"
                    placeholder="请选择"
                    show-search
                    option-filter-prop="children"
                    @search="onSearchProject"
                    @change="onChangeProject"
                >
                    <a-select-option
                        v-for="item in projectList"
                        :key="item.id"
                    >
                        {{ item.projectName }}
                    </a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item label="项目编码">
                {{ projectDetail.projectCode || '-' }}
            </a-form-model-item>
            <a-form-model-item label="项目品牌">
                {{ projectDetail.principalName || '-' }}
            </a-form-model-item>
            <a-form-model-item label="任务品牌">
                <span v-for="(item, index) in (taskDetail.carSeriesRelationList || [])" :key="item.id">{{ index != 0 ? '、' : '' }}{{ item.principalName }}</span>
            </a-form-model-item>
            <a-form-model-item label="项目预估周期">
                {{ projectDetail.startTime || '-' }} ~ {{ projectDetail.endTime || '-' }}
            </a-form-model-item>
            <a-form-model-item label="任务周期">
                {{ taskDetail.startTime || '-' }} ~ {{ taskDetail.endTime || '-' }}
            </a-form-model-item>
            <a-form-model-item label="项目KPI类型">
                {{ projectDetail.kpiType == 1 ? '线索量' : '-' }}
            </a-form-model-item>
            <a-form-model-item label="任务KPI类型">
                {{ taskDetail.kpiType == 1 ? '线索量' : '-' }}
            </a-form-model-item>
            <a-form-model-item label=" " :colon="false">
                <a-space>
                    <base-button
                        :title="'取消'"
                        @onClickBtn="handleBack"
                    ></base-button>
                    <base-button
                        :type="'primary'"
                        :title="'提交'"
                        :loading="isSubmit"
                        :disabled="isSubmit"
                        @onClickBtn="handleSubmit"
                    ></base-button>
                </a-space>
            </a-form-model-item>
        </a-form-model>

        <div style="margin-top: 40px;">PS：绑定后无法修改</div>
    </a-card>
</template>

<script>
export default {
    data() {
        return {
            id: this.$route.query.id,
            layout: {
                labelCol: { span: 6 },
                wrapperCol: { span: 11 }
            },
            dataForm: {
                projectId: undefined
            },
            projectDetail: {},
            taskDetail: {},
            rules: {
                projectId: [
                    { required: true, message: '请选择项目', trigger: 'change' }
                ]
            },
            projectList: [],
            isSubmit: false,

        }
    },
    created() {
        this.getProjectList()
        this.getDetail()
    },
    methods: {
        //获取项目列表
        getProjectList(val) {
            let params = {
                projectName: val,
                page: 1,
                size: 1000
            }
            this.$api.core.deliverManage.getProjectList(params).then((res) => {
                if(res.code == 200) {
                    this.projectList = res.data.list || []
                } else {
                    this.projectList = []
                    this.$message.error(`获取项目列表失败,${res.message}`)
                }
            })
        },
        //获取任务详情
        getDetail() {
            this.$api.core.deliverManage.getTaskDetail(this.id).then((res) => {
                if(res.code == 200) {
                    this.taskDetail = res.data
                } else {
                    this.$message.error(`获取任务详情,${res.message}`)
                }
            })
        },
        //获取项目详情
        getProjectDetail(id) {
            this.$api.core.deliverManage.getProjectDetail(id).then((res) => {
                if(res.code == 200) {
                    this.projectDetail = res.data
                } else {
                    this.$message.error(`获取项目详情,${res.message}`)
                }
            })
        },
        onSearchProject(val) {
            this.getProjectList(val)
        },
        onChangeProject() {
            this.getProjectDetail(this.dataForm.projectId)
        },
        //取消
        handleBack() {
            let that = this
            this.$confirm({
                content: h => <div><span style="color: red; display: block;">表单数据还未保存</span>离开数据将会丢失，是否确认离开？
                </div>,
                okText: '确定',
                cancelText: '取消',
                onOk () {
                    that.$router.push({
                        path: '/taskManage'
                    })
                }
            })
        },
        //提交
        handleSubmit() {
            this.$refs.dataForm.validate((valid, obj) => {
                if (valid) {
                    let params = {
                        missionId: this.id,
                        projectId: this.dataForm.projectId
                    }
                    this.$api.core.deliverManage.bindProject(params).then(res => {
                        this.isSubmit = false
                        if(res.code == 200) {
                            this.$message.success(`绑定成功`)
                            this.$router.push({
                                path: '/taskManage'
                            })
                        } else {
                            this.$message.error(`绑定失败，无法绑定此项目，请选择正确项目，${res.message}`)
                        }
                    })
                }
            })
        },
    }
}
</script>

<style scoped lang="less">
/deep/ .ant-page-header {
    padding-left: 0;
    padding-right: 0;
}
</style>